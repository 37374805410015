import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";
import loadable from "@loadable/component";

import store from "./redux/store";

import { getENV } from "./helpers/system";
import { getQueryString } from "./helpers/props";

import { PagePaths, PageRedirects } from "./config/system";
import { VanityLinks } from "./config/marketing";
import { CHATBOT } from "./config/feature-flags";

import "react-toastify/dist/ReactToastify.css";

const HomePage = loadable(() => import("./containers/home-page"));
const UserSignUp = loadable(() => import("./containers/sign-up"));
const UserSignUpCampaign = loadable(() => import("./containers/sign-up-campaign"));
const UserLogout = loadable(() => import("./containers/logout"));
const UserPreQual = loadable(() => import("./containers/pre-qualification"));
const UserConsent = loadable(() => import("./containers/user-consent"));
const Profile = loadable(() => import("./containers/profile"));
const Products = loadable(() => import("./containers/products"));
const Product = loadable(() => import("./containers/product"));
const Dashboard = loadable(() => import("./containers/dashboard"));
const LoanApplication = loadable(() => import("./containers/loan-application"));
const LoanView = loadable(() => import("./containers/loan-view"));
const LoanDisbursement = loadable(() => import("./containers/loan-disbursement"));
const LoanAcceptance = loadable(() => import("./containers/loan-acceptance"));
const UserPasswordReset = loadable(() => import("./containers/update-password"));
const UserPasswordGCPReset = loadable(() => import("./containers/update-password-gcp"));
const LoanApplicationForm = loadable(() => import("./containers/loan-application-form"));
const ExternalCallback = loadable(() => import("./containers/external-callback"));
const PartnersPage = loadable(() => import("./containers/lender-partners"));
// const LoanCalculator = loadable(() => import("./containers/loan-calculator"));
const TestimonialsPage = loadable(() => import("./containers/testimonials"));
const MGMPage = loadable(() => import("./containers/mgm"));
const ReferralPage = loadable(() => import("./containers/referral-handler"));
const ExternalRedirect = loadable(() => import("./containers/external-redirect"));
const NewsPage = loadable(() => import("./containers/news"));

const AboutPage = loadable(() => import("./containers/static/about-us"));
const TermsPage = loadable(() => import("./containers/static/terms"));
const PrivacyPage = loadable(() => import("./containers/static/privacy"));
const KitchenSink = loadable(() => import("./containers/static/kitchen-sink"));
// const TestBed = loadable(() => import("./containers/static/test-bed"));
const Error404 = loadable(() => import("./containers/static/404"));

// motorcycles related
const MotorcyclesHomePage = loadable(() => import("./containers/motorcycles/home-page"));
const MotorcyclesProducts = loadable(() => import("./containers/motorcycles/products"));
const MotorcyclesProduct = loadable(() => import("./containers/motorcycles/product"));
const MotorcyclesDashboard = loadable(() => import("./containers/motorcycles/dashboard"));

export default class App extends Component {
    constructor() {
        super();

        this.state = {
            showChatbot: false,
        };
    }

    async componentDidMount() {
        const { extensions = {} } = this.props;
        const { FeatureFlags } = extensions;

        const featureFlag = await FeatureFlags;

        const chatBotFlag = featureFlag?.toggles.find((flag) => flag.name === CHATBOT);

        let showChatbot = false;

        if (chatBotFlag) {
            showChatbot = chatBotFlag.enabled;
        }

        this.setState({ showChatbot });
    }

    renderRedirects() {
        const renderedRedirects = [];

        Object.keys(PageRedirects).forEach((oldPath) => {
            const targetPath = PageRedirects[oldPath];

            renderedRedirects.push(
                <Route
                    key={`redirect-${oldPath}`}
                    path={oldPath}
                    component={(props) => {
                        const targetPathParts = targetPath.substr(1).split("/");

                        const target = targetPathParts.map((targetPathPart) => {
                            const targetPathPartMatch = targetPathPart.match(/^:([a-z0-9-]+)/);

                            if (targetPathPartMatch) {
                                const [, varName] = targetPathPartMatch;

                                if (props.match && props.match.params && props.match.params[varName]) {
                                    return props.match.params[varName];
                                }
                                return false;
                            } else {
                                return targetPathPart;
                            }
                        });

                        return <Redirect to={`/${target.join("/")}`} />;
                    }}
                />,
            );
        });

        return renderedRedirects;
    }

    renderVanityLinks() {
        const renderedVanityLinks = [];

        Object.keys(VanityLinks).forEach((vanityLink) => {
            let target = VanityLinks[vanityLink];

            if (/^http[s]?:\/\//.test(target)) {
                renderedVanityLinks.push(
                    <Route key={vanityLink} path={vanityLink}>
                        <ExternalRedirect to={target} />
                    </Route>,
                );
            } else {
                if (typeof target === "object") {
                    target = target[getENV()];
                }

                let [pathname, search = false] = target.split("?");

                search = search ? `?${search}` : "";

                renderedVanityLinks.push(
                    <Route key={vanityLink} path={vanityLink}>
                        <Redirect to={{ pathname, search }} />
                    </Route>,
                );
            }
        });

        return renderedVanityLinks;
    }

    renderRoute(Component, config) {
        const { extensions = {} } = this.props;

        let renderedRoute;
        if (PagePaths[config]) {
            if (PagePaths[config] instanceof Array) {
                renderedRoute = [];
                PagePaths[config].forEach((path, pathIdx) => {
                    renderedRoute.push(
                        <Route
                            key={`${config}-${pathIdx}`}
                            exact
                            path={path}
                            //component={component}
                            render={(props) => <Component {...props} extensions={extensions} />}
                        ></Route>,
                    );
                });

                renderedRoute.reverse();
            } else {
                renderedRoute = (
                    <Route
                        key={config}
                        exact
                        path={PagePaths[config]}
                        render={(props) => <Component {...props} extensions={extensions} />}
                    ></Route>
                );
            }
        }

        return renderedRoute;
    }

    renderRoutes() {
        let renderedRoutes = [];

        renderedRoutes.push(this.renderRoute(HomePage, "HomePage"));
        renderedRoutes.push(this.renderRoute(UserSignUp, "UserSignUp"));
        renderedRoutes.push(this.renderRoute(UserSignUpCampaign, "UserSignUpCampaign"));
        renderedRoutes.push(this.renderRoute(UserSignUp, "UserSignUpStatus"));
        renderedRoutes.push(this.renderRoute(UserSignUp, "UserVerify"));
        renderedRoutes.push(this.renderRoute(UserLogout, "UserLogout"));
        renderedRoutes.push(this.renderRoute(UserPreQual, "UserPreQual"));
        renderedRoutes.push(this.renderRoute(UserPasswordReset, "UserPasswordReset"));
        renderedRoutes.push(this.renderRoute(UserPasswordGCPReset, "UserPasswordGCPReset"));
        renderedRoutes.push(this.renderRoute(UserConsent, "UserConsent"));
        renderedRoutes.push(this.renderRoute(Profile, "Profile"));
        renderedRoutes.push(this.renderRoute(Products, "Products"));
        renderedRoutes.push(this.renderRoute(Product, "Product"));
        renderedRoutes.push(this.renderRoute(LoanApplication, "LoanApplication"));
        renderedRoutes.push(this.renderRoute(LoanView, "LoanView"));
        renderedRoutes.push(this.renderRoute(LoanApplicationForm, "LoanApplicationForm"));
        renderedRoutes.push(this.renderRoute(LoanAcceptance, "LoanAcceptance"));
        renderedRoutes.push(this.renderRoute(LoanDisbursement, "LoanDisbursement"));
        renderedRoutes.push(this.renderRoute(Dashboard, "Dashboard"));
        renderedRoutes.push(this.renderRoute(AboutPage, "AboutPage"));
        renderedRoutes.push(this.renderRoute(TermsPage, "TermsPage"));
        renderedRoutes.push(this.renderRoute(PrivacyPage, "PrivacyPage"));
        renderedRoutes.push(this.renderRoute(ExternalCallback, "ExternalCallback"));
        renderedRoutes.push(this.renderRoute(PartnersPage, "PartnersPage"));
        // renderedRoutes.push(this.renderRoute(LoanCalculator, "LoanCalculator"));
        renderedRoutes.push(this.renderRoute(NewsPage, "NewsPage"));
        renderedRoutes.push(this.renderRoute(TestimonialsPage, "Testimonials"));
        renderedRoutes.push(this.renderRoute(MGMPage, "MGMPage"));
        renderedRoutes.push(this.renderRoute(ReferralPage, "ReferralPage"));

        // motorcycles related
        renderedRoutes.push(this.renderRoute(MotorcyclesHomePage, "MotorcyclesHomePage"));
        renderedRoutes.push(this.renderRoute(MotorcyclesProducts, "MotorcyclesProducts"));
        renderedRoutes.push(this.renderRoute(MotorcyclesProduct, "MotorcyclesProduct"));
        renderedRoutes.push(this.renderRoute(MotorcyclesDashboard, "MotorcyclesDashboard"));

        renderedRoutes.push(
            this.renderRoute(() => {
                window.location.href = "https://faq.seekcap.ph/";
                return null;
            }, "FAQPage"),
        );

        renderedRoutes.reverse();

        return renderedRoutes;
    }

    renderAnalytics() {
        const { showChatbot } = this.state;
        const gtmID = process.env.REACT_APP_GTM_ID || "GTM-NLSS6GJ";
        const gaID = process.env.REACT_APP_GA_ID || "AW-10926434257";
        const nonce = "hs3CWPxZzR";

        return (
            <Helmet>
                {/* Google Tag Manager */}
                <script defer nonce={nonce}>
                    {`
                        (function(w, d, s, l, i) {
                            w[l] = w[l] || [];
                            w[l].push({
                            "gtm.start": new Date().getTime(),
                            event: "gtm.js",
                            });
                            var f = d.getElementsByTagName(s)[0],
                            j = d.createElement(s),
                            dl = l != "dataLayer" ? "&l=" + l : "";
                            j.async = true;
                            j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
                            var n = d.querySelector("[nonce]");
                            n && j.setAttribute("nonce", "${nonce}");
                            f.parentNode.insertBefore(j, f);
                        })(window, document, "script", "dataLayer", "${gtmID}");
                    `}
                </script>
                {/* End Google Tag Manager */}
                {/* Global site tag (gtag.js) - Google Ads: 10926434257 */}
                <script defer nonce={nonce} async src={`https://www.googletagmanager.com/gtag/js?id=${gaID}`}></script>
                <script defer nonce={nonce}>
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());

                        gtag('config', '${gaID}');
                    `}
                </script>
                {/* <!-- Start of ubxseekcap Zendesk Widget script --> */}
                {showChatbot && (
                    <script
                        id="ze-snippet"
                        src="https://static.zdassets.com/ekr/snippet.js?key=5b241c9c-b25e-4800-a0a3-a68535625704"
                    >
                        {" "}
                    </script>
                )}
                {/* <!-- End of ubxseekcap Zendesk Widget script --> */}
            </Helmet>
        );
    }

    render() {
        return (
            <Provider store={store}>
                {this.renderAnalytics()}
                <Router>
                    <Switch>
                        {/*<Route exact path={"/loans"}>
                            <Redirect to="/dashboard/applications" />
                        </Route>
                        <Route
                            exact
                            path={"/draft/:id"}
                            component={(props) => {
                                console.log(props);
                                return <Redirect to={`/loans/${props.match.params.id}/draft/edit`} />;
                            }}
                        ></Route>*/}

                        {this.renderVanityLinks()}
                        {this.renderRoutes()}

                        <Route exact path="/kitchen-sink" component={KitchenSink} />
                        <Route
                            exact
                            path={PagePaths["Redirect"]}
                            render={(props) => {
                                const { to = false } = getQueryString(props);

                                return to ? <ExternalRedirect to={to} /> : <Redirect to="/" />;
                            }}
                        />
                        {this.renderRedirects()}
                        <Route component={Error404} />
                    </Switch>
                </Router>
                <ToastContainer position="top-center" hideProgressBar={true} />
            </Provider>
        );
    }
}
